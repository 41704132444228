html, body {
  height: 100% !important;
  margin: 0;
}

#root {
  height: 100%;
}

/* fixed toast ui background problem for darkmode */
.toastui-editor-main {
  background-color: white;
}